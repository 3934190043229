import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

// Compopnents
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMembers from "../components/teamMembers"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import teamStyles from "../pages/team.module.scss"
// import indexStyles from "../pages/index.module.scss"

export const query = graphql`
    query team {
        heroBackground: file(relativePath: { regex: "/rainbow/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo: file(relativePath: { regex: "/logo_transparent/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        strapiTeam{
            heroSubtitle
            heroTitle
            intro
            introTitle
        }

    }
`

const TeamPage = ({ data }) => {

    return (
        <Layout>
            <SEO title="Team" />
            <BackgroundImage
                className={`backgroundImage`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero mainMid`} />
            </BackgroundImage>

            <Container className={`heroTextBox`}>
                <h1 className={`smallH1`}>{data.strapiTeam.heroTitle}</h1>
                <span className={`headline`}>{data.strapiTeam.heroSubtitle}</span>
            </Container>

            {/* Section 2 */}
            <Container className={`mainMid my-5`}>
                <Row>
                    <Col className={`text-center`}>
                        <h2>
                        {data.strapiTeam.introTitle}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col className={`text-center`}>
                        
                        <ReactMarkdown source={data.strapiTeam.intro} escapeHtml={false} />
                    </Col>
                </Row>

                {/* Section 3 */}
                <TeamMembers useOpacity={true} />

            </Container>
        </Layout>
    )
}

export default TeamPage
